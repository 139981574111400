import React from 'react'
import "./how-generative-ai-works.css"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { MobileImageNode } from '../about-slider/slide-data'

function HowGenerativeAiWorks() {
    const data = useStaticQuery(graphql`
    query imageLogoQuery {
        allFile(filter: { internal: { mediaType: { regex: "/image/" } }, absolutePath: { regex: "/(common)/" } }) {
            nodes {
                relativePath
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: BLURRED)
                }
            }
        }
    }`)

    const cardData = [
        {
            title: "Answer",
            subtitle: "anything, instantly",
            image: "robo",
        },
        {
            title: "Automate",
            subtitle: "common responses",
            image: "automate",
        },
        {
            title: "Classify",
            subtitle: "issues & sentiment",
            image: "chart",
        },
        {
            title: "Generate",
            subtitle: "articles & content",
            image: "robo",
        },
        {
            title: "Summarize",
            subtitle: "conversations",
            image: "automate",
        },
        {
            title: "Translate",
            subtitle: "into multiple languages",
            image: "chart",
        },
    ];
    const findImage = (path: string) => {
        const image = data.allFile.nodes.find((node: MobileImageNode) => node.relativePath.includes(path));
        console.log(image);
        if (!image) {
            return null;
        }

        return image.childImageSharp.gatsbyImageData;
    };

    return (
        <div className='how-generative-ai-works'>
            <h2 className='bold text-h2 text-align-center title'>
                How it works
            </h2>
            <p className='text-lg-new text-align-center description'>
                {/* With AptEdge AnswerGPT&#8482; to index disparate systems and bring the most relevant knowledge, we are leveraging GPT to turn search results into human-like answers */}
                With AptEdge to bring together disparate knowledge sources and return the most relevant answers, we are leveraging purpose built AI for Case Resolution to turn search results into context specific, customer centric answers
            </p>

            <div className='container'>
                {cardData.map(each =>
                    <div className='card'>
                        <div className='image-block'>
                            <div className='mx-auto block w-fit' style={{height: "62px"}}>
                            <GatsbyImage alt={each.image} image={findImage(each.image)} />
                            </div>
                        </div>
                        <div>
                            <p className='text-teal title bold'>{each.title}</p>
                            <p className='text-black title'>{each.subtitle}</p>
                        </div>
                    </div>
                    )}
            </div>
        </div>
    )
}

export default HowGenerativeAiWorks;