import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import AppButton from '../../CustomComponents/app-button';
import './hero-section.css';
import preventEscalationHero from '../../../assets/use-cases/hero-dummy.png';
import ticketVolumeHero from '../../../assets/use-cases/ticket-volume-hero.png';
import improvedResolutionHero from '../../../assets/use-cases/improved-resolution-hero.png';
import improvedCsatHero from '../../../assets/use-cases/improved-csat-hero.png';

export const heroDescriptions = {
    'Prevent Escalations': "GPT powered answers for immediate Tier 1 resolution, reducing <span class='bold'>escalation up to 35%</span>",
    'Ticket Volume Reduction':
        "GPT powered answers to automate and deflect support cases, <span class='bold'>leading to 30% more agent productivity</span> ",
    'Improved Resolution Time':
        "GPT powered answers to <span class='bold'>improve</span> first contact resolutions and <span class='bold'>average handle time by 40%</span> ",
    'Improved CSAT':
        "<span class='bold'>GPT powered</span> answers creating smarter agents for <span class='bold'>better customer experience</span>"
};
const hero = {
    'Prevent Escalations': preventEscalationHero,
    'Ticket Volume Reduction': ticketVolumeHero,
    'Improved Resolution Time': improvedResolutionHero,
    'Improved CSAT': improvedCsatHero
};

function HeroSection({
    title,
    heroImage,
    description,
    isTechnology
}: {
    title: string;
    description?: string;
    heroImage?: IGatsbyImageData;
    isTechnology?: boolean | undefined;
}) {
    const useCasedescription = heroDescriptions[title as keyof typeof heroDescriptions]
        ? heroDescriptions[title as keyof typeof heroDescriptions]
        : "GPT powered answers for immediate Tier 1 resolution <span class='bold'>reducing escalation up to 35%</span>";

    const heroBgImage = hero[title as keyof typeof hero] ?? preventEscalationHero;

    // some adjustments to fit the hero image in usecases perfectly
    const csatSpecificStyle =
        title === 'Improved CSAT'
            ? {
                  transform: 'rotate(-2deg)',
                  bottom: '0.6em'
              }
            : {};

    const ticketVolumeSpecifciStyle =
        title === 'Ticket Volume Reduction'
            ? {
                  transform: 'rotate(-1deg)',
                  bottom: '0.5em'
              }
            : {};
    // usecase hero image
    const heroImageStyle = {
        backgroundImage: `url(${heroBgImage})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
        ...csatSpecificStyle,
        ...ticketVolumeSpecifciStyle
    };

    return (
        <div className={isTechnology ? 'hero-green-background' : 'hero-background'}>
            <div className="home-hero-section">
                <div className="hero-container">
                    <div className="hero-text-block">
                        {!isTechnology && <p>Use Cases for Knowledge Orchestration</p>}
                        <h1>{title}</h1>
                        {isTechnology ? (
                            <p className="text-lg-new description">{description}</p>
                        ) : (
                            <p
                                className="text-lg-new description"
                                dangerouslySetInnerHTML={{
                                    __html: `${useCasedescription}`
                                }}
                            />
                        )}

                        <div className="button-container">
                            <AppButton text="Read more" variant="variant-teal" linkTo={'#reason'} />
                            <AppButton text="Schedule Demo" variant="variant-white" linkTo="/get-started" />
                        </div>
                    </div>
                    {isTechnology ? (
                        <div className={'hero-image-block-technology-first'}></div>
                    ) : (
                        <div className="hero-image-block" style={heroImageStyle}></div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default HeroSection;
