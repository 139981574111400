import { graphql } from 'gatsby';
import React from 'react'
import AppLayout from '../components/AppLayout';
import HowGenerativeAiWorks from '../components/howGenerativeAiWorks';
import CtaBlock from '../components/components-group-one/CtaBlock';
import HeroSection from '../components/components-group-one/hero-section';
import Reasons from '../components/components-group-one/reason';
import { imageDataType } from './prevent-escalations';
import AppFAQAccordion from '../components/AppFaq';

function Analytics({ data }: { data: imageDataType }) {
    const pageData = {
        seoData: {
            title: "First-ever GPT-powered Answer Engine | AptEdge",
            canonicalLink: "",
            metaDescription: {
                metaDescription: "With AptEdge proprietary AI to index disparate systems and bring the most relevant knowledge, we are leveraging GPT to turn search results into human-like answers",
            },
            openGraphImage: {
                file: {
                    url: "",
                },
            },
        },
        // title: "AnswerGPT™",
        title: "Answer AI",
        // description: `AptEdge is building the industry’s first
        // “Answer Engine” powered by Generative AI`,
        description: `AptEdge is building the industry’s first
        Answer Engine powered by Generative AI`,
        isTechnology: true,
        heroImage: data.heroImg.childImageSharp.gatsbyImageData,

        reasons: {
            title: "Powered by Generative AI",
            subTitle: "Unifying Knowledge for Instant Answers",
            // content: "AptEdge is building the industry’s first “Answer Engine” powered by Generative AI ",
            content: "AptEdge is building the industry’s first Answer Engine powered by Generative AI ",
            image: data.reasonImg.childImageSharp.gatsbyImageData,
        },
        testimonial: [
            {
                company: "everbridge",
                name: "Renee Bastine",
                bio: "Sr. Director, Global Customer Support",
                logo: data.testimonialLogoOne.childImageSharp.gatsbyImageData,
                content: "With AptEdge, we’re resolving tickets faster, reducing escalations, and achieving 99% CSAT.",
            },
            {
                company: "cloud bees",
                name: "Ryan Smith",
                bio: "Sr. Support Manager",
                logo: data.testimonialLogoTwo.childImageSharp.gatsbyImageData,
                content: "AptEdge has been key in bridging silos between tools we use on a daily basis to build software."
            },
        ],
        contentWithImageData: [
            {
                title: "GPT Powered Answer Engine",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            },
            {
                isReversed: true,
                title: "Improve Resolution Times for Voice and Chat",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            },
        ],
        dummyImg: data.dummyImg.childImageSharp.gatsbyImageData,
    }
    const faqData = [
        {
            id: 4,
            question: 'How does AptEdge leverage AI and machine learning?',
            answer:
                'AptEdge leverages AI in two primary ways. First, our unified search uses AI to understand the semantic meaning of the query, and then identifies conceptual links between the knowledge articles and referenced support cases to find the most relevant answer sources. Second, AptEdge utilizes LLMs to create a human-readable answer to the question from top sources. The agent can then edit that answer directly or further enhance it with AI.',
        },
        {
            id: 9,
            question: 'What makes AptEdge different from other answer engines?',
            answer: `AptEdge’s proprietary AptEdge technology leverages our own AI models to look through disparate knowledge sources before leveraging GPT LMMs to create the answer.  This experience lives right in the ticketing platform that the agent is using.

              Additionally, AptEdge also captures ticket trends and commonalities, allowing the support team to deflect and prevent escalations.
              `,
        },
        {
            id: 13,
            question: 'How does AptEdge help in customer support with GPT?',
            answer:
                'AptEdge AI finds answers to customer questions and uses Generative AI to create human-readable responses in seconds.',
        },
    ]
    return (
        <AppLayout seoData={pageData.seoData}>
            <HeroSection
                title={pageData.title}
                heroImage={pageData.heroImage}
                description={pageData.description}
                isTechnology={pageData.isTechnology}
            />
            <Reasons reasons={pageData.reasons} />
            <HowGenerativeAiWorks />
            <AppFAQAccordion faqData={faqData} />
            <CtaBlock ctaTitle={undefined} />
        </AppLayout>
    )
}

export default Analytics;

export const query = graphql`
    query GptRoadmapQuery {
        reasonImg: file(relativePath: { eq: "technology/gpt-roadmap.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: NONE, quality: 100)
            }
        }
        testimonialLogoOne: file(relativePath: { eq: "utkarsh/logo-everbridge.png" }) {
          childImageSharp {
              gatsbyImageData(placeholder: NONE)
          }
      }
      testimonialLogoTwo: file(relativePath: { eq: "utkarsh/logo-cloudbees.png" }) {
        childImageSharp {
            gatsbyImageData(placeholder: NONE)
        }
    }
        dummyImg: file(relativePath: { eq: "use-cases/dummy.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
        }
      }
        heroImg: file(relativePath: { eq: "technology/technology-home-hero.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE )
        }
        }
      }`