import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import { reasonsType } from '../../../templates/use-cases';
import './prevent-escalation-reason.css'

function Reasons({ reasons }: { reasons: reasonsType }) {
    return (
        <div className='prevent-escalation' id="reason">
            <div className={`content-container ${reasons.title === "AI-Driven Knowledge Orchestration" ? "" : "width-80"}`} >
                <div className='text-block'>
                    <h2 className='bold text-h2 text-align-center title'>
                        {reasons.title}
                    </h2>
                    {reasons.subTitle && <h2 className='bold text-h2 text-align-center title text-black'>
                        {reasons.subTitle}
                    </h2>}
                    <p className='text-lg-new text-align-center description'>
                        {reasons.content}
                    </p>
                </div>
                <div className='image-block'>
                    <GatsbyImage
                        alt={'shows the average cost of resolving ticket for level 1, 22 dollar and desktop support ticket is 22 dollar'}
                        image={reasons.image}
                    />
                    {/* <StaticImage
                    src="../../../assets/use-cases/prevent-escalations.png"
                    className=""
                    alt="tech stacks"
                    placeholder="none"
                    loading="eager"
                    layout="constrained"
                    quality={90}
                /> */}
                </div>
            </div>
        </div>
    )
}
export default Reasons;