import React from 'react'
import Footer from '../footer'
import HtmlHead from '../html-head'
import Navbar from '../navbar'

export type seoDataType = {
    title: string;
    canonicalLink?: string;
    metaDescription: {
        metaDescription: string;
    };
    openGraphImage: {
        file: {
            url: string;
        }
    };
};

const AppLayout = ({ seoData, children }: { seoData: seoDataType | null, children: any }) => {
    return (
        <>
            {
                seoData &&
                <HtmlHead
                    title={seoData.title}
                    metaDescription={seoData.metaDescription.metaDescription}
                    canonicalLink={seoData.canonicalLink}
                    openGraphImage={seoData.openGraphImage.file.url}
                />
            }
            <Navbar />
            {children}
            <Footer />
        </>
    )
}

export default AppLayout;